<template>
  <div class="LandPage">
    <div class="card">
      <b>Total Control Desktop</b>
      <img class="brand-logo" :src="whitelabel.brand_logo" alt=""/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useWhiteLabel } from '@/stores/whitelabel'

export default {
  name: 'LandPage',
  computed: {
    ...mapState(useWhiteLabel, ['whitelabel'])
  }
}
</script>

<style lang="scss" scoped>
.LandPage {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .card {
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 320px;
    height: 240px;

    .brand-logo {
      margin-top: 1rem;
      width: 160px;
    }
  }
}
</style>
