<template>
  <div class="Terms container">
    <div class="page">
      <h3>TERMOS E CONDIÇÕES DE USO DESTE APP</h3>

      <p>Seja bem-vindo ao nosso APP. Leia com atenção todos os termos abaixo.</p>

      <p>
        Este documento regulamenta todos os direitos e obrigações com todos que acessam o APP,
        denominado neste termo como “VISITANTE”, reguardado todos os direitos previstos na legislação, trazem as
        cláusulas
        abaixo como requisito para acesso e visita do mesmo, situado no endereço https://acesso.center e em seus
        subdominios.
      </p>
      <p>
        A permanência no website implica-se automaticamente na leitura e aceitação tácita do presente termos de uso a
        seguir. Este termo foi atualizado pela última vez em 08 de janeiro de 2023.
      </p>
      <h4>DA FUNÇÃO DO SITE</h4>
      <p>
        Este APP foi criado e desenvolvido com a função de proporcionar gestão de acesso de alta qualidade na forma de
        prestação de serviço.
      </p>
      <p>
        É de responsabilidade do usuário de usar todas as informações presentes no APP com senso crítico, utilizando
        apenas como fonte de informação, e sempre buscando especialistas da área para a solução concreta do seu
        conflito.
      </p>
      <h4>DO ACEITE DOS TERMOS</h4>
      <p>
        Este termo especifica e exige que todo usuário ao acessar o APP, leia e compreenda todas as cláusulas do
        mesmo, visto que ele estabelece entre a APP e o VISITANTE direitos e obrigações entre ambas as partes, aceitos
        expressamente pelo VISITANTE a permanecer usando o APP.
      </p>
      <p>
        Ao continuar acessando o APP, o VISITANTE expressa que aceita e entende todas as cláusulas, assim como concorda
        integralmente com cada uma delas, sendo este aceite imprescindível para a permanência na mesma. Caso o VISITANTE
        discorde de alguma cláusula ou termo deste contrato, o mesmo deve imediatamente interromper sua navegação de
        todas
        as formas e meios.
      </p>
      <p>
        Este termo pode e irá ser atualizado periodicamente pela APP, que se resguarda no direito de alteração, sem
        qualquer tipo de aviso prévio e comunicação. É importante que o VISITANTE confira sempre se houve movimentação e
        qual foi a última atualização do mesmo no começo da página.
      </p>
      <h4>
        DO ACESSO AO APP
      </h4>
      <p>
        O APP funciona normalmente 24 (vinte e quatro) horas por dia, porém podem ocorrer pequenas
        interrupções de forma temporária para ajustes, manutenção, mudança de servidores, falhas técnicas ou por ordem
        de
        força maior, que podem deixa-lo indisponível por tempo limitado.
      </p>
      <p>
        A APP não se responsabiliza por nenhuma perda de oportunidade ou prejuízos que esta indisponibilidade temporária
        possa gerar aos usuários.
      </p>
      <p>
        Em caso de manutenção que exigirem um tempo maior, a APP irá informar previamente aos clientes da necessidade e
        do tempo previsto de retorno.
      </p>
      <p>
        Todos os dados estão protegidos conforme a Lei Geral de Proteção de Dados, e ao realizar o cadastro junto ao
        APP,
        o
        VISITANTE concorda integralmente com a coleta de dados conforme a Lei e com a Política de Privacidade da APP.
      </p>

      <h4>DAS OBRIGAÇÕES</h4>
      <p>
        O VISITANTE ao utilizar o APP, concorda integralmente em:
      </p>
      <p>
        De nenhuma forma ou meio realizar qualquer tipo de ação que tente invadir, hacker, destruir ou prejudicar a
        estrutura do APP ou de seus parceiros comerciais. Incluindo-se, mas não se limitando, ao
        envio de vírus de computador, de ataques de DDOS, de acesso indevido por falhas da mesma ou quaisquer outras
        forma
        e
        meio. De não realizar divulgação indevida nos comentários do APP de conteúdo de SPAM, empresas concorrentes,
        vírus,
        conteúdo que não possua direitos autorais ou quaisquer outros que não seja pertinente a discussão daquele texto,
        vídeo ou imagem. Da proibição em reproduzir qualquer conteúdo do APP ou plataforma sem autorização expressa,
        podendo responder civil e criminalmente pelo mesmo. Com a Política de Privacidade do APP, assim como tratamos os
        dados referentes ao cadastro e visita no APP, podendo a qualquer momento e forma, requerer a exclusão dos
        mesmos,
        através do formulário de contato.
      </p>

      <h4>DOS TERMOS GERAIS</h4>
      <p>
        O APP poderá apresentar hiperlinks durante toda a sua navegação, que podem levar diretamente para sites
        externos.
      </p>
      <p>
        Caso o usuário acesse um link externo, o APP não tem nenhuma responsabilidade pelo meio, sendo uma mera
        indicação
        de
        complementação de
        conteúdo, ficando o mesmo responsável pelo acesso, assim como sobre quaisquer ações que venham a realizar.
      </p>
      <p>
        Este Termo de uso é valido a partir de 08 de janeiro de 2023.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Terms',
  data () {
    return {}
  },
  mounted () {
    //
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.Terms {

  .page {
    padding: 2rem;
    background-color: #ffffff;
  }
}
</style>
